export const uploadListener = {
  init: function(){
    // console.log('upload listener')
    addEventListener("direct-upload:initialize", event => {
      const { target, detail } = event
      const { id, file } = detail

      // const hereTarget = document.getElementsByName(target.id);
      // target.insertAdjacentHTML("beforebegin", `
      //   <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
      //     <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
      //     <span class="direct-upload__filename"></span>
      //   </div>
      // `)
      // target.previousElementSibling.querySelector(`.direct-upload__filename`).textContent = file.name
    })

    addEventListener("direct-upload:start", event => {

      if (document.getElementById(`save_button`)){
        document.getElementById(`save_button`).disabled = true;
      }
        
      const { id } = event.detail
      if(id==null){
        var element = document.getElementById(`direct-upload-new`)
      }else{
        var element = document.getElementById(`direct-upload-${id}`)
      }
      if(element!=null){
        element.classList.remove("direct-upload--pending")
        $(element).parents().children(".direct-upload-wrapper").show();
        if($(this).parents().children("video")[0] != 'undefined' && $(this).parents().children("video")[0] != null ){
          $(element).parents().children("video").addClass("hide");
          $(element).parents().children("video")[0].pause();
        }
      }
    })

    addEventListener("direct-upload:progress", event => {
      const { id, progress } = event.detail
      if(id==null){
        var progressElement = document.getElementById(`direct-upload-progress-new`)
      }else{
        var progressElement = document.getElementById(`direct-upload-progress-${id}`)
      }
      if(progressElement!=null){
        progressElement.style.width = `${progress}%`
      }
    })

    addEventListener("direct-upload:error", event => {
      event.preventDefault()
      const { id, error } = event.detail
      if(id==null){
        var element = document.getElementById(`direct-upload-new`)
      }else{
        var element = document.getElementById(`direct-upload-${id}`)
      }
      if(element!=null){
        element.classList.add("direct-upload--error")
        element.setAttribute("title", error)
      }
    })

    addEventListener("direct-upload:end", event => {
      if (document.getElementById(`save_button`)){
        document.getElementById(`save_button`).disabled = false;
      }
      const { id } = event.detail
      if(id==null){
        var element = document.getElementById(`direct-upload-new`)
      }else{
        var element = document.getElementById(`direct-upload-${id}`)
      }
      $(`#upload-icon-${id}`).attr("hidden",false);
      if(element!=null){
        element.classList.add("direct-upload--complete")
      }
    })
  }
}