import { DirectUpload } from "@rails/activestorage"
import Rails from '@rails/ujs'

// See DirectUploadController from Rails Active Storage source
export class CustomUploader {
  constructor(input, file) {
    this.modelId = input.getAttribute("data-modelId");
    this.path = input.getAttribute('data-path');
    this.className = input.getAttribute('data-className');
    this.assetName = input.getAttribute('data-assetName');
    this.input = input
    this.file = file
    this.directUpload = new DirectUpload(this.file, this.url, this)
    this.dispatch("initialize")
  }

  start() {
    const hiddenInput = document.createElement("input")
    hiddenInput.type = "hidden"
    hiddenInput.name = this.input.name
    hiddenInput.classList.add('cache')
    this.input.insertAdjacentElement("beforebegin", hiddenInput)
    this.dispatch("start")

    this.directUpload.create((error, attributes) => {
      if (error) {
        hiddenInput.parentNode.removeChild(hiddenInput)
        this.dispatchError(error)
      } else {
        hiddenInput.value = attributes.signed_id
      }

      this.dispatch("end")

      updateModelVideo(this.modelId, this.path, this.className, attributes.signed_id, this.assetName)
      // callback(error)
    })
  }

  uploadRequestDidProgress(event) {
    const progress = event.loaded / event.total * 100
    if (progress) {
      this.dispatch("progress", { progress })
    }
  }

  get url() {
    return this.input.getAttribute("data-direct-upload-url")
  }

  dispatch(name, detail = {}) {
    detail.file = this.file
    detail.id = this.modelId
    return dispatchEvent(this.input, `direct-upload:${name}`, { detail })
  }

  dispatchError(error) {
    const event = this.dispatch("error", { error })
    if (!event.defaultPrevented) {
      alert(error)
    }
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.dispatch("before-storage-request", { xhr })
    xhr.upload.addEventListener("progress", event => this.uploadRequestDidProgress(event))
  }
}

function dispatchEvent(element, type, eventInit = {}) {
  const { disabled } = element
  const { bubbles, cancelable, detail } = eventInit
  const event = document.createEvent("Event")

  event.initEvent(type, bubbles || true, cancelable || true)
  event.detail = detail || {}

  try {
    element.disabled = false
    element.dispatchEvent(event)
  } finally {
    element.disabled = disabled
  }

  return event
}

function updateModelVideo(modelId, path, className, modelData, assetName) {
  var name;
  if (className != 'CoursePDF') {
    name = className.replace(/([A-Z])/g, "_$1").toLowerCase().substr(1);
  } else {
    name = 'course_pdf'
  }
  // if (className != 'CoursePDF') {
  //   data.append(`${name}[video]`, modelData)
  //   data.append('class_name', className)
  // } else {
  //   data.append(`${name}[pdf_file]`, modelData)
  //   data.append('class_name', className)
  // }
  const data = new FormData();
  data.append(`${name}[${assetName}]`, modelData)
  data.append('class_name', className)

  Rails.ajax({
    url: path,
    type: 'put',
    dataType: 'script',
    data: data,
    success: function(data) {},
    error: function(data) {}
  })
}
