export var Subscription = {
  init: function(){
    $(document).ready(function() {
      var radios = document.querySelectorAll('input[type=radio]');
      function changeHandler(event) {
        var this_radio = $(this);
        if ( this_radio[0].checked == true ) {
          var selected = this_radio[0].closest('label')
          $("[data-target='current_price']").each(function(){
            $(this).text(selected.getElementsByClassName('content')[0].innerHTML);
          });
          $("[data-target='current_plan']").each(function(){
            $(this).text(selected.getElementsByClassName('title')[0].innerHTML);
          });
        }
      }
      Array.prototype.forEach.call(radios, function(radio) {
        radio.addEventListener('change', changeHandler);
      });
    });
  }
}
