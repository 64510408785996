import { CustomUploader } from 'packs/directUpload'

export const uploadFile = {
  init: function(){
    // console.log('upload file listener')
    const inputs = document.querySelectorAll('input[type=file]')
    // Bind to file drop - use the ondrop on a parent element or use a
    //  library like Dropzone
    const onDrop = (event) => {
      event.preventDefault()
      const files = event.dataTransfer.files;
      Array.from(files).forEach(file => uploadFile(file))
    }
    // Bind to normal file selection
    inputs.forEach(
      function(input) {
        if (input.id.split('_')[3] != 'new') {
          // console.log('eidt file', input.id);
          input.addEventListener('change', (event) => {
            Array.from(input.files).forEach(file => uploadFile(file, input))
            // you might clear the selected files from the input
            input.value = null
          })
        }else{
          // console.log('new file');

          $('#featured_video_FeaturedVideo_new, #featured_video_FeaturedVideo_new_button').on('change', function(){
            let input = this;
            // console.log("new video");
            if (input.files && input.files[0]) {
              var reader = new FileReader();
              reader.readAsDataURL(input.files[0]); // convert to base64 string
              reader.onload = function(e) {
                $('#featured_video_new').attr('src', e.target.result);
                var canvas = document.createElement("CANVAS");
                var imageObj = document.createElement("IMG");
                var video = document.getElementById("featured_video_new");
                video.onloadedmetadata = function() {
                  this.currentTime = 0;
                };
                video.onseeked = function(e) {
                  canvas.height = video.videoHeight;
                  canvas.width = video.videoWidth;
                  var ctx = canvas.getContext('2d');
                  ctx.drawImage(video, 0, 0);
                  var url = canvas.toDataURL("image/png")
                  imageObj.src = url
                  document.getElementById("video_bg").style.backgroundImage = "url("+url+ ")";
                };
                $('#upload-wrapper').hide();
                $('#direct-button').removeClass("hide");
                $('#small_upload_button').removeClass("hide");
                $('#featured_video_new').addClass("hide");
                $('.direct-upload-wrapper').css("display", '');

              }
              $(this).parents('.video-upload-wrapper.new-video').addClass('has-img');
            }
          });
          
          $('#live_stream_LiveStream_new, #live_stream_LiveStream_new_button').on('change', function(){
            let input = this;
            // console.log("new video");
            if (input.files && input.files[0]) {
              var reader = new FileReader();
              reader.readAsDataURL(input.files[0]); // convert to base64 string
              reader.onload = function(e) {
                $('#live_stream_new').attr('src', e.target.result);
                var canvas = document.createElement("CANVAS");
                var imageObj = document.createElement("IMG");
                var video = document.getElementById("live_stream_new");
                video.onloadedmetadata = function() {
                  this.currentTime = 0;
                };
                video.onseeked = function(e) {
                  canvas.height = video.videoHeight;
                  canvas.width = video.videoWidth;
                  var ctx = canvas.getContext('2d');
                  ctx.drawImage(video, 0, 0);
                  var url = canvas.toDataURL("image/png")
                  imageObj.src = url
                  document.getElementById("video_bg").style.backgroundImage = "url("+url+ ")";
                };
                $('#upload-wrapper').hide();
                $('#direct-button').removeClass("hide");
                $('#small_upload_button').removeClass("hide");
                $('#live_stream_new').addClass("hide");
                $('.direct-upload-wrapper').css("display", '');

              }
              $(this).parents('.video-upload-wrapper.new-video').addClass('has-img');
            }
          });

          $('#support_video_DisciplineSupportVideo_new, #support_video_DisciplineSupportVideo_new_button').on('change', function(){
            let input = this;
            // console.log("new video");
            if (input.files && input.files[0]) {
              var reader = new FileReader();
              reader.readAsDataURL(input.files[0]); // convert to base64 string
              reader.onload = function(e) {
                $('#support_video_new').attr('src', e.target.result);
                var canvas = document.createElement("CANVAS");
                var imageObj = document.createElement("IMG");
                var video = document.getElementById("support_video_new");
                video.onloadedmetadata = function() {
                  this.currentTime = 0;
                };
                video.onseeked = function(e) {
                  canvas.height = video.videoHeight;
                  canvas.width = video.videoWidth;
                  var ctx = canvas.getContext('2d');
                  ctx.drawImage(video, 0, 0);
                  var url = canvas.toDataURL("image/png")
                  imageObj.src = url
                  document.getElementById("video_bg").style.backgroundImage = "url("+url+ ")";
                };
                $('#upload-wrapper').hide();
                $('#direct-button').removeClass("hide");
                $('#small_upload_button').removeClass("hide");
                $('#support_video_new').addClass("hide");
                $('.direct-upload-wrapper').css("display", '');

              }
              $(this).parents('.video-upload-wrapper.new-video').addClass('has-img');
            }
          });

          $('#discipline_video_DisciplineVideo_new, #discipline_video_DisciplineVideo_new_button').on('change', function(){
            let input = this;
            if (input.files && input.files[0]) {
              var reader = new FileReader();
              reader.readAsArrayBuffer(input.files[0]) // convert to base64 string
              reader.onload = function(e) {
                let buffer = e.target.result;
                let videoBlob = new Blob([new Uint8Array(buffer)], { type: 'video/mp4' });
                let previewUrl = window.URL.createObjectURL(videoBlob);
                $('#discipline_video_new').attr('src', previewUrl);
                var canvas = document.createElement("CANVAS");
                var imageObj = document.createElement("IMG");
                var video = document.getElementById("discipline_video_new");
                video.onloadedmetadata = function() {
                  this.currentTime = 0;
                };
                video.onseeked = function(e) {
                  canvas.height = video.videoHeight;
                  canvas.width = video.videoWidth;
                  var ctx = canvas.getContext('2d');
                  ctx.drawImage(video, 0, 0);
                  var url = canvas.toDataURL("image/png")
                  imageObj.src = url
                  document.getElementById("video_bg").style.backgroundImage = "url("+url+ ")";
                };
                $('#upload-wrapper').hide();
                $('#direct-button').removeClass("hide");
                $('#small_upload_button').removeClass("hide");
                $('#discipline_video_new').addClass("hide");
                $('.direct-upload-wrapper').css("display", '');

              }
              $(this).parents('.video-upload-wrapper.new-video').addClass('has-img');
            }
          });

          $('#inspo_video_InspoVideo_new, #inspo_video_InspoVideo_new_button').on('change', function(){
            let input = this;
            if (input.files && input.files[0]) {
              var reader = new FileReader();
              reader.readAsArrayBuffer(input.files[0]); // convert to base64 string
              reader.onload = function(e) {
                let buffer = e.target.result;
                let videoBlob = new Blob([new Uint8Array(buffer)], { type: 'video/mp4' });
                let previewUrl = window.URL.createObjectURL(videoBlob);
                $('#inspo_video_new').attr('src', previewUrl);
                var canvas = document.createElement("CANVAS");
                var imageObj = document.createElement("IMG");
                var video = document.getElementById("inspo_video_new");
                video.onloadedmetadata = function() {
                  this.currentTime = 0;
                };
                video.onseeked = function(e) {
                  canvas.height = video.videoHeight;
                  canvas.width = video.videoWidth;
                  var ctx = canvas.getContext('2d');
                  ctx.drawImage(video, 0, 0);
                  var url = canvas.toDataURL("image/png")
                  imageObj.src = url
                  document.getElementById("video_bg").style.backgroundImage = "url("+url+ ")";
                };
                $('#upload-wrapper').hide();
                $('#direct-button').removeClass("hide");
                $('#small_upload_button').removeClass("hide");
                $('#inspo_video_new').addClass("hide");
                $('.direct-upload-wrapper').css("display", '');

              }
              $(this).parents('.video-upload-wrapper.new-video').addClass('has-img');
            }
          });

          $('#cover_image_FeaturedVideo_new').on('change', function(){
            let input = this;
            // console.log("new video");
            if (input.files && input.files[0]) {
              var reader = new FileReader();
              reader.readAsDataURL(input.files[0]); // convert to base64 string
              reader.onload = function(e) {
                document.getElementById("video_bg").style.backgroundImage = "url("+e.target.result+ ")";
                $('#featured_video_new').addClass("hide");
                $('.direct-upload-wrapper').css("display", '');
              }
              $(this).parents('.video-upload-wrapper.new-video').addClass('has-img');
            }
          });

          $('#cover_image_LiveStream_new').on('change', function(){
            let input = this;
            // console.log("new video");
            if (input.files && input.files[0]) {
              var reader = new FileReader();
              reader.readAsDataURL(input.files[0]); // convert to base64 string
              reader.onload = function(e) {
                document.getElementById("video_bg").style.backgroundImage = "url("+e.target.result+ ")";
                $('#live_stream_new').addClass("hide");
                $('.direct-upload-wrapper').css("display", '');
              }
              $(this).parents('.video-upload-wrapper.new-video').addClass('has-img');
            }
          });

          $('#cover_image_DisciplineSupportVideo_new').on('change', function(){
            let input = this;
            // console.log("new video");
            if (input.files && input.files[0]) {
              var reader = new FileReader();
              reader.readAsDataURL(input.files[0]); // convert to base64 string
              reader.onload = function(e) {
                document.getElementById("video_bg").style.backgroundImage = "url("+e.target.result+ ")";
                $('#support_video_new').addClass("hide");
                $('.direct-upload-wrapper').css("display", '');
              }
              $(this).parents('.video-upload-wrapper.new-video').addClass('has-img');
            }
          });

        }
      }
    );
    const uploadFile = (file, input) => {
      // your form needs the file_field direct_upload: true, which
      //  provides data-direct-upload-url
      const upload = new CustomUploader(input, file)
      upload.start();
    }
  }
}
