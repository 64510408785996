export var Coupon = {
  init: function(){
    $(document).ready(function() {
      var ajaxRequest;
      $("input[name='coupon_code']").keyup(function() {
        clearTimeout(ajaxRequest);
        ajaxRequest = setTimeout(doneTyping, 700);
      });

      function doneTyping () {
        var value = $("input[name='coupon_code']").val();
        Coupon.check_coupon(value);
      }

      $("input[name='coupon_code']").on('keydown', function () {
        clearTimeout(ajaxRequest);
      });
      // Coupon.check_coupon($("input[name='coupon_code']").val());
    });
  },

  check_coupon: function(coupon){
    var coupon_input = $("input[name='coupon_code']");

    $.ajax({
      url: '/user/promo-code',
      dataType: 'JSON',
      method: 'GET',
      data: {coupon_code: coupon_input.val()}
    })
    .done(function(data){
      // console.log(data['result']);
      if(data['result'] == 'success'){
        Coupon.success(data['message']);
      }else{
        if(coupon_input.val() == ''){
          $('.coupon-text').attr('class', 'hide coupon-text');
        }else{
          Coupon.fail(data['message']);
        }
      };
    })
    .fail(function(data){
      alert(Coupon.error_message());
    })
    .always(function(data){
      Coupon.set_coupon_price();
    })
  },

  success: function(text){
    $('.coupon-text').attr('class', 'hide coupon-text');
    $('.coupon-text').addClass('text-green');
    Coupon.set_message(text);
  },

  fail: function(text){
    $('.coupon-text').attr('class', 'hide coupon-text');
    $('.coupon-text').addClass('text-red');
    Coupon.set_message(text);
  },

  set_coupon_price: function(){
    $("[data-target='product-field']").each(function(){
      var product_field = $(this);
      var coupon = $("input[name='coupon_code']");
      $.ajax({
        url: '/user/discount/price',
        dataType: 'JSON',
        method: 'GET',
        data: {coupon_code: coupon.val(), product_id: $(this).data('value')}
      })
      .done(function(data){
        if(product_field[0].getElementsByTagName('input')[0].checked == true){
          $("[data-target='current_price']").each(function(){
            $(this).text(data['discount_amount']);
          })
        }
        product_field.find("[data-target='product-price-field']").text(data['discount_amount']);
      })
      .fail(function(data){
        alert(Coupon.error_message());
      })
    });
  },

  set_message: function(text){
    var coupon_text = $("[data-target='coupon-text']");
    coupon_text.text(text);
    coupon_text.removeClass('hide');
  },

  error_message: function(){
    return 'Sorry, we have a problem.';
  },
}
