import "jquery-countdown"
import $ from "jquery"


export var Countdown = {
  init: function(){
    $(document).ready(function() {
      var countdown = $('#defaultCountdown')
      countdown.countdown(countdown[0].dataset.variable, function(event) {
        $('#countdown-d').text(
          event.strftime('%D ')
        );
        $('#countdown-h').text(
          event.strftime('%H ')
        );
        $('#countdown-m').text(
          event.strftime('%M ')
        );
      }); 
    });
  }
}
