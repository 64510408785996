export var UploadButton = {
  init: function(){
    // Attach the change event listener to change the label of all input[type=file] elements
    $("[data-target='file-upload-btn']").change(function(){
      var imgurl = "url(" + URL.createObjectURL(this.files[0]) + ")";
      var preview = $(this).closest("[data-target='upload-field']").find(".preview-pane");
      $(this).closest("[data-target='upload-field']").find(".img_info_field").text(this.files[0].name);
      preview.empty();
      preview.removeClass('blank');
      console.log("img upload")
      preview.css('background-image', imgurl);
    });
  },

  acceptVideo: function(jquery_element){
    jquery_element.attr("accept", "video/mov, video/mp4");
  },

  acceptImage: function(jquery_element){
    jquery_element.attr("accept", "image/png, image/jpg, image/jpeg");
  },
}
