export var layout = {
  init: function() {
    layout.radioTab();
    layout.deviseHeader();
    layout.radioTabInputGroup();
    layout.videoControl();
    layout.ellipsisPane();
    layout.globalNotice();
    layout.videoTags();
    layout.buttonSwitch();
    layout.selectAllCheckbox();
  },
  radioTab: function() {
    $("*[data-radio-tab] .radio-tab").removeClass('active');
    $("*[data-radio-tab] input[type='radio']:checked").parents(".radio-tab").addClass('active');
    $("*[data-radio-tab] input[type='radio']").on('change', function() {
      $("*[data-radio-tab] .radio-tab").removeClass('active');
      $(this).parents('.radio-tab').addClass('active');
    });
  },
  deviseHeader: function() {
    setPadding();
    $(window).resize(function() {
      setPadding();
    });
    function setPadding() {
      var headerH = $('body.devise-consumer header.devise-consumer-header').outerHeight();
      $('body.devise-consumer .login-main').css('padding-top', headerH);
    }
  },
  radioTabInputGroup: function() {
    $("*[data-radio-tab-input-group]").each(function() {
      var inputGroup = $(this);
      var wrapper = $(this).siblings(".radio-tab-input-group");
      _checkRadio(inputGroup);
      wrapper.find(".radio-tab").on("click", function() {
        var selector = "#" + $(this).data("id");
        inputGroup.find(selector).prop("checked", true);
        inputGroup.find(selector).trigger("click");
        _checkRadio(inputGroup);
      });
    });
    function _checkRadio(_this) {
      _this.siblings(".radio-tab-input-group").find(".radio-tab").removeClass('active');
      if(_this.find("input[type='radio']:checked").length != 0){
        var selector = ".radio-tab[data-id =" + _this.find("input[type='radio']:checked").attr('id') + "]";
        $(selector).addClass('active');
      }
    }
  },
  videoControl: function() {
    var video = $('video.consumer-video');
    var video_wrapper_html = "<div class='video-wrapper'></div>";
    var play_button_html = "<div class='play-button'></div>";
    // video.removeAttr('controls');
    video.wrap(video_wrapper_html);
    var wrapper = $('.video-wrapper');
    wrapper.append(play_button_html);
    var play_button = $('.video-wrapper .play-button');
    play_button.on("click", function(){
      togglePlayPause($(this).siblings('video'));
    });
    video.on("play", function() {
      checkVideo($(this));
    });
    video.on("pause", function() {
      checkVideo($(this));
    });
    video.on('ended', function(){
      videoEnded($(this));
    });
    function togglePlayPause(_thisVideo) {
      var target_video = _thisVideo;
      var play_button = _thisVideo.siblings('.play-button');
      if (target_video.get(0).paused) {
        target_video.get(0).play();
      }else {
        target_video.get(0).pause();
      }
    }
    function checkVideo(_thisVideo) {
      var play_button = _thisVideo.siblings('.play-button');
      if (_thisVideo.get(0).paused) {
        play_button.attr('class', 'play-button pause');
      }else {
        play_button.attr('class', 'play-button play');
      }
    }
    function videoEnded(_this) {
      var target_play_button = _this.siblings('.play-button');
      target_play_button.attr('class', 'play-button end');
    }
  },
  ellipsisPane: function() {
    if($("[data-ellipsis-pane]").length != 0){
      console.log('ellipsis pane run!');
      $("[data-ellipsis-pane]").each(function(){
        const handleW = 30;
        let pane = $(this);
        let paneW = $(this).width();
        let strictW = paneW - handleW;
        let paneItemW = [];
        pane.find("[data-pane-item]").each(function() {
          paneItemW.push($(this).outerWidth(true));
        });
        let sumW = 0;
        let index = 0;
        let wrapFlag = false;
        while(sumW <= strictW) {
          sumW += paneItemW[index];
          index++;
          if(sumW > strictW) {
            wrapFlag = true;
          }
          if(index > paneItemW.length-1) {
            break;
          }
        }
        if(wrapFlag) {
          // "[data-pane-item]:not(:lt(3))"
          pane.find("[data-pane-item]:not(:lt(" + (index - 1) + "))").wrapAll('<div class="ellipsised-pane"></div>');
          $('<div class="ellipsis-handle"></div>').insertBefore(".ellipsised-pane");
          let handle = pane.find('.ellipsis-handle');
          let ellipsisedPane = pane.find('.ellipsised-pane');
          handle.on('click', function() {
            ellipsisedPaneToggle(ellipsisedPane, handle);
          });
        }
      });
    }
    function ellipsisedPaneToggle(_this, handle) {
      if(_this.hasClass('show')){
        _this.removeClass('show');
        handle.removeClass('active');
      }else{
        _this.addClass('show');
        handle.addClass('active');
      }
    }
  },
  globalNotice: function() {
    if ($('.global-notice').length > 0) {
      $('.global-notice').addClass('anim');
      var timer = setTimeout(function(){
        $('.global-notice').fadeOut(3000);
      }, 5000);
      $('.global-notice').hover(
        function(){
          clearTimeout(timer);
          $(this).stop(true, true).fadeIn();
        },
        function(){
          $('.global-notice').fadeOut(3000);
        }
      );
      $('.global-notice a.close-button').on('click', function() {
        $('.global-notice').hide();
      });
    }
  },
  // admin add video tags layout
  videoTags: function() {
    $(document).on('nested:fieldAdded', function(event){
      let field = event.field;
      field.find('.tag-item').attr("contenteditable", "true");
      tagEdit();
    });
    function tagEdit(){
      $('.tag-item[contenteditable=true]').each(function() {
        let tag_item = $(this);
        tag_item.on('input', function(){
          let tag_content = $(this).text().trim();
          $(this).find('input').val(tag_content);
        });
      });
    }
  },
  // admin button switch
  buttonSwitch: function() {
    $('[data-button-switch]').each(function() {
      let wrapper = $(this);
      let button = wrapper.find('[data-switch-button]');
      let act_id = wrapper.find('input:checked').attr('id');
      let act_selector = "[data-id='" + act_id + "']";
      $(act_selector).addClass('active');
      button.on('click', function() {
        button.attr('class', '');
        let selector = 'input#' + $(this).data('id');
        $(selector).prop("checked", true);
        $(this).addClass('active');
      });
    });
  },
  // select all checkbox
  selectAllCheckbox: function() {
    $(".checkbox-with-selectall input[data-target='select-all']").on("click", function(){
      var cbxs = $(this).closest('.checkbox-with-selectall').find("input[data-target='select-all-options']");
      cbxs.prop("checked", $(this).prop("checked"));
    });
    $(".checkbox-with-selectall input[data-target='select-all-options']").on("change", function(){
      let wrapper = $(this).parents(".checkbox-with-selectall");
      let opt_cbxs_num = $(this).parents(".checkbox-with-selectall").find("input[data-target='select-all-options']").length;
      let checked_opt_cbxs_num = $(this).parents(".checkbox-with-selectall").find("input[data-target='select-all-options']:checked").length;
      if(checked_opt_cbxs_num == opt_cbxs_num) {
        wrapper.find("input[data-target='select-all']").prop("checked", true);
      }else {
        wrapper.find("input[data-target='select-all']").prop("checked", false);
      }
    });
  },
}
