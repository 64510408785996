/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("jquery")
require("foundation-sites")
require("nested_form")
// require("packs/foundation_ui")
// require("foundation-datepicker")
require("jquery-countdown")
// require("jquery-countdown-es")
const flatpickr = require("flatpickr").default;
// const select2 = require("select2")

const images = require.context('images', true)
const imagePath = (name) => images(name, true)
Foundation.addToJquery($)

// import { FoundationDatePicker } from "packs/foundation_ui"
import { uploadFile } from "packs/uploadFile"
import { uploadListener } from "packs/uploadListener"
import { Payment } from "packs/payments"
import { Coupon } from "packs/user/coupons"
import { Subscription } from "packs/user/subscription"
import { UploadButton } from "packs/file_upload"
import { layout } from "packs/layout"
import { select2 } from "packs/select2"
import { ImageCroppie } from "packs/image_croppie"
import { CroppieUpload } from "packs/croppie_upload"
import { Countdown } from "packs/countdown"
import { SubscriptionIntro } from "./user/subscription_intro"

export {
  Payment,
  Coupon,
  Subscription,
  layout,
  UploadButton,
  ImageCroppie,
  CroppieUpload,
  Countdown,
  SubscriptionIntro
}

window.addEventListener('DOMContentLoaded', (_event) => {
  $(document).foundation();

  $(document).ready(function(){
    $('video').bind('contextmenu',function() { return false; });
  });

  layout.init();

  UploadButton.init();

  select2.init();

  $("[data-target='foundation-date-picker']").flatpickr({
    altInput: true,
    altFormat: 'd/m/Y'
  });

  $("[data-target='select-box']").select2();

  if (document.querySelectorAll("[data-upload-file]").length) {
    uploadFile.init()
    uploadListener.init()
  };

  flatpickr("[data-target='time-picker']", {
    enableTime: true,
    noCalendar: true,
    dateFormat: "H:i",
  });
})
