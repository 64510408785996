export var SubscriptionIntro = {
  init: function(){
    const gettingStartedAccordionChildren = new Map();

    gettingStartedAccordionChildren.set('accordion-item-1', [
      '• 2 video introductions to the course',
      '• 2 videos explaining equipment and ocean knowledge',
      '• 4 videos explaining technique on the 3 foundational movements in surfing',
      '• 4 PDFs to download and use to read & keep notes',
      '• 12 videos allowing you to follow Brad and practice the movements',
      '• 2 videos explaining how to practice Wave Ki',
      '• Full Access to the Wave Ki Body Program',
      '• Access to Brad’s monthly members only live streams and past streams',
      '• Access to monthly realtime Q&A sessions with Brad'
    ]);
    gettingStartedAccordionChildren.set('accordion-item-2', [
      '• The Art and technique of taking off on both white water and green-faced waves',
      '• The Art and technique of toe-side turning and how to practice it',
      '• The Art and technique of heel-side turning and how to practice it',
      '• Effective warm up movements and stretches to prepare your body for Wave Ki or a surf',
      '• Ocean knowledge to understand how to manage yourself safely and effectively in the surf',
      '• How to read and catch waves',
      '• What equipment you will need'
    ]);
    gettingStartedAccordionChildren.set('accordion-item-3', [
      '• Overall mobility, strength and fitness will improve',
      '• More awareness in feet',
      '• Less tension in the water',
      '• Lessen chances of injury',
      '• Priority access to one-on-one Wave Ki & surf sessions with Brad (remote and in-person)',
      '• Priority access to the Wave Ki retreats & workshops',
      '• Priority access to limited runs of Wave Ki Merchandise'
    ]);

    const fundamentalAccordionChildren = new Map();

    fundamentalAccordionChildren.set('accordion-item-1', [
      '• 2 video introductions to the course',
      '• 6 warm up videos (2 short and 4 long)',
      '• 14 videos explaining technique on 14 key (BS and FS) manoeuvres',
      '• 44 videos allowing you to follow Brad and practice the manoeuvres and combos',
      '• Written guidance for each form',
      '• Access to Brad’s members only live streams and archived practices',
      '• Access to Q&A sessions with Brad',
      '• Access to the Wave Ki member portal'
    ]);
    fundamentalAccordionChildren.set('accordion-item-2', [
      '• How to take off like the pro\'s do',
      '• The Art and technique of the FS bottom turn and how to practice it',
      '• The Art and technique of the BS bottom turn and how to practice it',
      '• The Art and technique of the the FS top turn and how to practice it',
      '• The Art and technique of the BS top turn and how to practice it',
      '• How to practice the FS take off to bottom turn to top turn combo',
      '• How to practice the BS take off to bottom turn to top turn combo',
      '• The Art and technique of the BS cutback and how to practice it',
      '• The Art and technique of the FS cutback and how to practice it',
      '• The Art and technique of the BS tube ride and how to practice it',
      '• The Art and technique of the FS tube ride and how to practice it',
      '• The Art and technique of the BS floater and how to practice it',
      '• The Art and technique of the FS floater and how to practice it',
      '• The Art and technique of the BS air and how to practice it',
      '• The Art and technique of the FS air and how to practice it',
      '• How to practice the FS bottom turn to top turn combo to cutback to tube ride to floater to air',
      '• How to practice the BS bottom turn to top turn combo to cutback to tube ride to floater to air',
      '• Effective warm up movements and stretches to prepare your body for Wave Ki or a surf.'
    ]);
    fundamentalAccordionChildren.set('accordion-item-3', [
      '• Overall mobility, strength and fitness will improve',
      '• More awareness in feet',
      '• Less tension in the water',
      '• Lessen chances of injury',
      '• Priority access to one-on-one Wave Ki & surf sessions with Brad (remote and in-person)',
      '• Priority access to the Wave Ki retreats & workshops',
      '• Priority access to limited runs of Wave Ki Merchandise'
    ]);

    const gsConfirmSubtitles = [
      "This program will build the foundation for your surfing journey with Wave Ki. It is designed for people new to surfing and looking to start off on the right (or left!) foot, surfers who haven't surfed much, and surfers who have had a long layoff.",
      'Not sure which program is best for you? Contact us at admin@waveki.com and we will point you in the right direction.'
    ];
    const fundamentalConfirmSubtitles = [
      'This program will help the proficient surfer transform their approach to riding waves. Our original program, Wave Ki Fundamentals 1 & 2 will open the door to improving all areas of your surfing.',
      'Not sure which program is best for you? Contact us at admin@waveki.com and we will point you in the right direction.'
    ];

    $(function() {
      subscriptionTypeOnChange()
      $('input[name="subscription_type[]"]').on('change', function() {
        subscriptionTypeOnChange()
      });
    });

    function subscriptionTypeOnChange() {
      updateAccordionChildren();
      updateConfirmModal();
    };

    function updateAccordionChildren() {
      var accordionChildren = $('input[name="subscription_type[]"]:checked').first().val() === 'GS' ? gettingStartedAccordionChildren : fundamentalAccordionChildren;

      $('.accordion-content').empty();
      accordionChildren.forEach((value, key) => {
        value.forEach((item) => {
          $(`#${key} .accordion-content`).append(`<p>${item}</p>`);
        });
      });
    };

    function updateConfirmModal() {
      var subscriptionType = $('input[name="subscription_type[]"]:checked').first().val();
      var confirmSubtitles = subscriptionType === 'GS' ? gsConfirmSubtitles : fundamentalConfirmSubtitles;

      $('#confirmModal .pane-header .title').text(subscriptionType === 'GS' ? 'Getting Started Program' : 'Fundamentals v1 Program');
      $('#confirmModal .pane-header .subtitle').empty();
      confirmSubtitles.forEach((item) => {
        $('#confirmModal .pane-header .subtitle').append(`<p>${item}</p>`);
      });
      $('#gs_btn').css('display', subscriptionType === 'GS' ? '' : 'none');
      $('#fundamental_btn').css('display', subscriptionType === 'GS' ? 'none' : '');
    }
  }
}
