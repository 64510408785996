export var Payment = {
  init: function(stripe_pk){
    var stripe = Stripe(stripe_pk);
    // Create an instance of Elements.
    var elements = stripe.elements();
    var isSubmiting = false;
    var enableSubmit = false;
    // Custom styling can be passed to options when creating an Element.
    // (Note that this demo uses a wider set of styles than the guide below.)
    var style = {
      base: {
        color: '#324961',
        fontFamily: '"Lato", sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: 'rgba(255,255,255,0)'
        }
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    };

    // Create an instance of the card Element.
    // var card = elements.create('card', {hidePostalCode: true, style: style});
    var cardNumber = elements.create('cardNumber', {hidePostalCode: true, style: style});
    var cardExpiry = elements.create('cardExpiry', {hidePostalCode: true, style: style});
    var cardCvc = elements.create('cardCvc', {hidePostalCode: true, style: style});

    var fillCard = false;
    var fillExpiry = false;
    var fillCvc = false;
    // Add an instance of the card Element into the `card-element` <div>.
    // card.mount('#card-element');
    cardNumber.mount('#card-number');
    cardCvc.mount('#card-cvc');
    cardExpiry.mount('#card-expiry');

    $("form").submit(function(e){
      if(enableSubmit){
        isSubmiting = true;
        enableSubmit = false;
        Payment.disabledSubmitBtn();
      }
    });

    function setToken(result) {
      var errorElement = $("#card-errors");
      errorElement.removeClass('visible');
      if (result.token) {
        $("#stripe_token").val(result.token.id);
        enableSubmit = true;
        $("form").submit();
      } else if (result.error) {
        errorElement.text(result.error.message);
        errorElement.addClass('visible');
        enableSubmit = false;
        isSubmiting = false;
        Payment.enableSubmitBtn();
      }else{
        errorElement.text("");
      }
    }

    cardNumber.on('change', function(event) {
      setToken(event);
    });

    $("[data-target='pay-btn']").click(function(e){
      e.preventDefault();
      var products = $("input[name='subscription_plan_id[]']:checked").val();
      if ( !jQuery.isEmptyObject(products) ){
        isSubmiting = true;
        $("[data-target='pay-btn']").prop('disabled', true);
        // e.preventDefault();
        stripe.createToken(cardNumber).then(setToken);
      }else{
        const modal = new Foundation.Reveal($('#select_course_alert'))
        modal.open();
        $('[data-close]').on('click', function(){
          modal.close();
        })
      }
    });

    $("[data-target='change-btn']").click(function(e){
      e.preventDefault();
      $("[data-target='change-btn']").prop('disabled', true);
      if(fillCard||fillExpiry||fillExpiry){
        stripe.createToken(cardNumber).then(setToken);
      }else{
        $("form").submit();
      }
    });

    $("[data-target='pay-btn']").on('disabledSet', function(e){
      setTimeout(function(){
        if(!isSubmiting){
          Payment.enableSubmitBtn();
        }
      },0);
    });

    elements.getElement('cardNumber').on('change',function(e){
      if(e.empty){
        fillCard = false;
      }else{
        fillCard = true;
      }
    });

    elements.getElement('cardExpiry').on('change',function(e){
      if(e.empty){
        fillExpiry = false;
      }else{
        fillExpiry = true;
      }
    });

    elements.getElement('cardCvc').on('change',function(e){
      if(e.empty){
        fillCvc = false;
      }else{
        fillCvc = true;
      }
    });
  },

  enableSubmitBtn: function(){
    $("[data-target='pay-btn']").prop('disabled', false);
    $("[data-target='change-btn']").prop('disabled', false);
  },

  disabledSubmitBtn: function(){
    $("[data-target='pay-btn']").prop('disabled', true);
    $("[data-target='change-btn']").prop('disabled', true);
  }
}
