export var CroppieUpload = {
  initUpload: function(croppie_div_field, modal_reveal, upload_to_profile_image = false) {
    var $uploadCrop;

    function readFile(input) {
      if (input.files && input.files[0]) {
        var reader = new FileReader();

        reader.onload = function (e) {
          croppie_div_field.addClass('ready');
          $uploadCrop.croppie('bind', {
            url: e.target.result
          }).then(function(){
            // console.log('jQuery bind complete');
          });
        }

        reader.readAsDataURL(input.files[0]);
      }else {
        alert("Sorry - you're browser doesn't support the FileReader API");
      }
    }

    $uploadCrop = croppie_div_field.find("[data-target='croppie-div-circle']").croppie({
      viewport: {
        width: 300,
        height: 300,
        type: 'circle'
      },
      enableExif: true
    });

    // hide image if there are no image
    $("[data-target = 'croppie-div-field']").each(function() {
      if(typeof $(this).find('img.cr-image').attr('src') == 'undefined') {
        $(this).find('img.cr-image').hide();
      }
    });

    $('#select_image').on('change', function () { readFile(this); });

    croppie_div_field.find("[data-target='croppie-btn']").on('click', function (ev) {
      $uploadCrop.croppie('result', {
        type: 'base64',
        size: 'viewport'
      }).then(function (resp) {
        if(resp && $('#select_image').val() != ""){
          $.ajax({
            url: '/user/profile/profile-image',
            dataType: 'JSON',
            method: 'POST',
            data: { image_blob: resp, upload_to_profile_image: upload_to_profile_image }
          })
          .done(function(data){
            $("#user_profile_image").val(data['image']);
            $('#user_check_upload_profile_image').val(true);
            modal_reveal.closest("[data-target='upload-field']").find(".preview-pane").css('background-image', `url(${data['image']})`);
          })
          .fail(function(data){
            alert("Sorry - we can not process your image");
            modal_reveal.closest("[data-target='upload-field']").find(".preview-pane").css('background-image', `url(${data['image']})`);
          })
        }
      });
    });
  }
}
